import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { CurrencyInputConfig } from '@app/shared/interfaces/input.interface';

@Component({
  selector: 'sliqpay-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CurrencyInputComponent implements ControlValueAccessor {
  @Input() config!: CurrencyInputConfig | null;
  @Input() value = '';
  @Output() focusOut = new EventEmitter();
  @Output() changed = new EventEmitter();

  onChange!: (value: number) => void;
  onTouch!: () => void;

  private isFocused = false;

  public get hasError(): ValidationErrors | null {
    return this.control ? this.control.errors : null;
  }

  public get isRequired(): boolean {
    return this.control.control?.hasValidator(Validators.required) || false;
  }

  public get disabled(): boolean {
    return this.config?.disabled || this.control?.disabled ? true : false;
  }

  constructor(@Self() @Optional() public control: NgControl, private fb: UntypedFormBuilder) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  writeValue(value: any): void {
    const num = value ? parseFloat(value).toFixed(2) : null;
    this.value = !this.isFocused ? num : value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onBlur(value: string): void {
    this.value = (+value).toFixed(2);
    this.isFocused = false;
    this.onChange(+this.value);
    this.focusOut.emit(+this.value);

    this.onTouch();
  }

  onKeyup(event: KeyboardEvent, value: string): void {
    if (
      (event.key >= '0' && event.key <= '9') || // Numeric keys 0-9
      event.key === 'Backspace' || // Backspace
      event.key === '.' // Period
    ) {
      this.onChange(+value);
      this.changed.emit(+value);
    }
  }

  onFocus(): void {
    this.isFocused = true;
  }
}
